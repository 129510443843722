// const bodyWrap = document.querySelector("body");
// const menuToggle = document.querySelector(".site-menu-toggle");
// const closeMenu = document.querySelector(".close-menu");
// const hamburger = document.querySelector(".hamburger");
// const menuMobile = document.querySelector(".mobile-nav-wrap");
// const searchToggle = document.querySelector(".search-toggle");
// const searchWrap = document.querySelector(".search-overlay");
// const backTop = document.querySelector(".back-to-top");
// const counterItem = document.querySelectorAll(".counter");
// const setBackground = document.querySelectorAll("[setBackground]");
// const sideNav = document.querySelector(".sidenav-group");
// const filterSelect = document.querySelector(".custom-tab-select select");
// const filterToggle = document.querySelector(".filter-toggle");
// const closeFilter = document.querySelector(".close-filter");
// const mobileFilter = document.querySelector(".mobile-filter-wrap");

// document.addEventListener("DOMContentLoaded", () => {
// 	swiperInit();
// 	hideElement();
// 	mappingFunc();
// 	generalFunction.init();
// });

// window.addEventListener("resize", function () {
// 	let width = this.window.innerWidth;
// 	if (width <= 1200.1) {
// 		hideElement();
// 		mappingFunc();
// 	}
// });
// window.addEventListener("scroll", function () {
// 	const width = this.window.innerWidth;

// 	const scrollTop = window.scrollY || document.documentElement.scrollTop;

// 	if (width >= 1200.1) {
// 		if (
// 			document.body.scrollTop > 100 ||
// 			document.documentElement.scrollTop > 100
// 		) {
// 			bodyWrap.classList.add("minimize");
// 		} else {
// 			bodyWrap.classList.remove("minimize");
// 		}
// 	}

// 	// if (scrollTop > window.innerHeight) {
// 	// 	sideNav.classList.add("show");
// 	// } else {
// 	// 	sideNav.classList.remove("show");
// 	// }
// });
// document.addEventListener("click", (event) => {
// 	if (!event.target.closest(".search-toggle, .search-overlay")) {
// 		searchWrap.classList.remove("is-open");
// 	}
// 	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
// 		menuMobile.classList.remove("is-open");
// 		bodyWrap.classList.remove("overlay-bg");
// 		hamburger.classList.remove("is-active");
// 	}
// });
// $("body").on("click", ".dealer-list .item", function (e) {
// 	let map = $(this).attr("data-map");
// 	$(".dealer-list .item").removeClass("is-active");
// 	$(this).addClass("is-active");
// 	$(".map-wrapper").attr("class", "map-wrapper");
// 	$(".map-wrapper iframe").attr("src", map);
// });
// Fancybox.bind("[data-fancybox]", {
// 	parentEl: document.body[0], // Element containing main structure
// });

// const generalFunction = {
// 	toggleFunc: () => {
// 		menuToggle.addEventListener("click", () => {
// 			menuMobile.classList.toggle("is-open");
// 			hamburger.classList.toggle("is-active");
// 			bodyWrap.classList.toggle("overlay-bg");
// 		});
// 		searchToggle.addEventListener("click", () => {
// 			searchWrap.classList.toggle("is-open");
// 			bodyWrap.classList.toggle("overlay-bg");
// 		});
// 		closeMenu.addEventListener("click", () => {
// 			menuMobile.classList.remove("is-open");
// 			hamburger.classList.remove("is-active");
// 			bodyWrap.classList.remove("overlay-bg");
// 		});
// 		$(".toggle-wrap").each(function (index, element) {
// 			let $this = $(this);
// 			$this.addClass("toggle-instance-" + index);
// 			$(".toggle-instance-" + index + " .toggle-item").click(function () {
// 				if ($(this).hasClass("is-toggle")) {
// 					$(this).find(".article").slideUp();
// 					$(this).removeClass("is-toggle");
// 				} else {
// 					$(this).find(".article").slideDown();
// 					$(this).addClass("is-toggle");
// 				}
// 			});
// 		});
// 		if (filterSelect != null) {
// 			filterSelect.addEventListener("change", function () {
// 				let val = this.value;
// 				document
// 					.querySelectorAll(".tab-select")
// 					.forEach((item) => item.classList.remove("active"));
// 				document.getElementById(val).classList.add("active");
// 			});
// 		}
// 		if (filterToggle !== null) {
// 			filterToggle.addEventListener("click", () => {
// 				mobileFilter.classList.add("is-open");
// 				hamburger.classList.remove("is-active");
// 				bodyWrap.classList.add("overlay-bg");
// 			});
// 			closeFilter.addEventListener("click", () => {
// 				mobileFilter.classList.remove("is-open");
// 				hamburger.classList.remove("is-active");
// 				bodyWrap.classList.remove("overlay-bg");
// 			});
// 		}
// 	},
// 	backTop: () => {
// 		// backTop.addEventListener("click", (event) => {
// 		// 	event.preventDefault();
// 		// 	$("html, body").animate({ scrollTop: 0 }, "300");
// 		// });
// 	},
// 	filterItem: () => {
// 		$(".filter-display-wrap").each(function () {
// 			let x = 5;
// 			$(this).find(".row").slice(0, 5).show().css({
// 				display: "flex",
// 			});
// 			let size = $(this).find(".row").length;
// 			if (size < x || size === x) {
// 				$(this).find(".ajax-btn-wrap").addClass("disble-btn");
// 			}
// 			$(".filter-display-wrap")
// 				.find(".btn-primary")
// 				.on("click", function (e) {
// 					e.preventDefault();
// 					x = x + 3;
// 					$(this)
// 						.parents(".filter-display-wrap")
// 						.find(".row")
// 						.slice(0, x)
// 						.slideDown()
// 						.css({
// 							display: "flex",
// 						});
// 					if (x >= size || size < x) {
// 						$(this).slideUp();
// 					}
// 				});
// 		});
// 		$(".filter-table-wrap").each(function () {
// 			let x = 6;
// 			$(this).find("tbody tr").slice(0, 6).show();
// 			let size = $(this).find("tbody tr").length;
// 			if (size < x || size == x) {
// 				$(this).find(".ajax-btn-wrap").addClass("disble-btn");
// 			}
// 			$(".filter-table-wrap")
// 				.find(".btn-view-more")
// 				.on("click", function (e) {
// 					e.preventDefault();
// 					x = x + 6;
// 					$(this)
// 						.parents(".filter-table-wrap")
// 						.find("tbody tr")
// 						.slice(0, x)
// 						.slideDown();
// 					if (x >= size || size < x) {
// 						$(this).parent().addClass("disble-btn");
// 					}
// 				});
// 		});
// 	},
// 	tabFunction: () => {
// 		$(".tab-nav a").on("click", function () {
// 			$(this).parents(".tab-nav").find("li").removeClass("active");
// 			$(this).parents("li").addClass("active");

// 			var tab = $(this).attr("data-type");
// 			$(this).parents("section").find(".tab-item").removeClass("active");
// 			$(this)
// 				.parents("section")
// 				.find("#" + tab)
// 				.addClass("active");
// 		});
// 		$(".swiper-tab .year").on("click", function () {
// 			let tab = $(this).attr("tab-link");
// 			$(this)
// 				.parents(".tab-item")
// 				.find(".tab-item-swiper")
// 				.removeClass("active");
// 			$(this)
// 				.parents(".tab-item")
// 				.find("#" + tab)
// 				.addClass("active");
// 		});

// 		$(".nav-tabs a").on("click", function () {
// 			let tabId = $(this).attr("data-tab");
// 			$(".tab-content").removeClass("active");

// 			$("." + tabId).addClass("active");
// 			$(".tab").removeClass("active");
// 			$(this).addClass("active");
// 		});
// 	},
// 	scrollreveal: () => {
// 		let fadeDown = {
// 			origin: "top",
// 			duration: 1100,
// 			delay: 220,
// 			interval: 150,
// 			reset: false,
// 			distance: "25%",
// 		};
// 		let fadeUp = {
// 			origin: "bottom",
// 			duration: 1100,
// 			delay: 500,
// 			interval: 50,
// 			reset: false,
// 			distance: "25%",
// 		};
// 		let fadeLeft = {
// 			origin: "left",
// 			duration: 1100,
// 			delay: 200,
// 			interval: 50,
// 			reset: false,
// 			distance: "25%",
// 		};
// 		let fadeRight = {
// 			origin: "right",
// 			duration: 1100,
// 			delay: 200,
// 			interval: 50,
// 			reset: false,
// 			distance: "25%",
// 		};
// 		let fadeIn = {
// 			origin: "top",
// 			duration: 1100,
// 			delay: 220,
// 			interval: 150,
// 			reset: false,
// 			distance: 0,
// 		};
// 		ScrollReveal().reveal(".fd-down", fadeDown, {
// 			easing: "cubic-bezier(.38,.37,.42,.42)",
// 		});
// 		ScrollReveal().reveal(".fd-up", fadeUp, {
// 			easing: "cubic-bezier(.38,.37,.42,.42)",
// 		});
// 		ScrollReveal().reveal(".fd-left", fadeLeft, {
// 			easing: "cubic-bezier(.38,.37,.42,.42)",
// 		});
// 		ScrollReveal().reveal(".fd-right", fadeRight, {
// 			easing: "cubic-bezier(.38,.37,.42,.42)",
// 		});
// 		ScrollReveal().reveal(".fd-in", fadeIn, {
// 			easing: "cubic-bezier(.38,.37,.42,.42)",
// 		});
// 	},
// 	dropdownMenu: () => {
// 		if ($(window).width() < 1200.1) {
// 			$(".drop-down .title em").on("click", function () {
// 				if ($(this).parents(".drop-down").hasClass("is-open")) {
// 					$(".drop-down .nav-sub").slideUp();
// 					$(".drop-down").removeClass("is-open");
// 				} else {
// 					$(".drop-down .nav-sub").slideUp();
// 					$(".drop-down").removeClass("is-open");
// 					$(this).parent().next().slideDown();
// 					$(this).parents(".drop-down").addClass("is-open");
// 				}
// 			});
// 			$(".tertiary-menu-toggle").on("click", function () {
// 				$(".nav-tertiary-menu").slideToggle();
// 			});
// 		}
// 	},

// 	counterAnimate: () => {
// 		if (counterItem !== null) {
// 			if (counterItem.length) {
// 				const counterUp = window.counterUp.default;
// 				const callback = (entries) => {
// 					entries.forEach((entry) => {
// 						const el = entry.target;
// 						if (
// 							entry.isIntersecting &&
// 							!el.classList.contains("is-visible")
// 						) {
// 							counterUp(el, {
// 								duration: 2000,
// 								delay: 200,
// 							});
// 							el.classList.add("is-visible");
// 						}
// 					});
// 				};
// 				const IO = new IntersectionObserver(callback, { threshold: 1 });
// 				counterItem.forEach((counter) => {
// 					IO.observe(counter);
// 				});
// 			}
// 		}
// 	},
// 	setBackground: () => {
// 		setBackground.forEach((attributes) => {
// 			attributes.style.cssText = `
// 			background-image: url(${attributes.getAttribute("setBackground")});
// 			background-size: cover;
// 			background-position: center;
// 		  `;
// 		});
// 	},

// 	hideElement: () => {
// 		if ($(window).width() < 1200.1) {
// 			const [search, menu] = [".search-overlay", ".nav-primary-menu"].map(
// 				(selector) => document.querySelector(selector)
// 			);

// 			[search, menu].forEach((el) => (el.style.display = "flex"));
// 		}
// 		//append html
// 		if ($("body").hasClass("product-list-page")) {
// 			if ($(".product-filter-wrap").length) {
// 				$(".product-filter-wrap").appendTo(
// 					".mobile-filter-wrap  .filter-wrap "
// 				);
// 			}
// 		}
// 	},
// 	expandContent: () => {
// 		$(".read-more-wrap").each(function (index) {
// 			$(this).addClass("read-instance-" + index);
// 			let $this = $(".read-instance-" + index);
// 			$this.each(function () {
// 				let height = $this.find(".article").outerHeight();
// 				if (height < 300) {
// 					$this.find(".btn-read-more").addClass("hide");
// 				} else {
// 					$this.find(".article").css({
// 						height: "300",
// 					});
// 				}
// 				let button = $this.find(".btn-read-more");
// 				let content = $this;
// 				button.on("click", function () {
// 					content.toggleClass("active");
// 				});
// 			});
// 		});
// 		$(".rm-wrap").each(function (index) {
// 			$(this).addClass("read-inst-" + index);
// 			let $this = $(".read-inst-" + index);
// 			$this.each(function () {
// 				let button = $this.find(".btn-read-more");
// 				let content = $this;
// 				button.on("click", function () {
// 					content.toggleClass("active");
// 				});
// 			});
// 		});
// 	},

// 	init: () => {
// 		generalFunction.toggleFunc();
// 		generalFunction.backTop();
// 		generalFunction.tabFunction();
// 		generalFunction.dropdownMenu();
// 		generalFunction.counterAnimate();
// 		generalFunction.setBackground();
// 		generalFunction.hideElement();
// 		generalFunction.scrollreveal();
// 		generalFunction.expandContent();
// 		generalFunction.filterItem();
// 	},
// };

// function hideElement() {
// 	if ($(window).width() < 1200.1) {
// 		const [search] = [".search-wrap"].map((selector) =>
// 			document.querySelector(selector)
// 		);

// 		[search].forEach((el) => (el.style.display = "flex"));
// 	}
// }
// function swiperInit() {



// 	var primarySwiper = new Swiper(".primary-banner .swiper", {
// 		// Optional parameters
// 		slidesPerView: 1,
// 		observer: true,
// 		observeParents: true,
// 		preventInteractionOnTransition: true,
// 		speed: 1205,
// 		autoplay: {
// 			delay: 4000,
// 			disableOnInteraction: false,
// 		},
// 		lazy: {
// 			loadPrevNext: true,
// 		},
// 		navigation: {
// 			nextEl: ".primary-banner .nav-arrow-next",
// 			prevEl: ".primary-banner .nav-arrow-prev",
// 		},
// 		pagination: {
// 			el: ".swiper-pagination",
// 			clickable: true,
// 		},
// 	});

// 	var partnerSwiper = new Swiper(".home-partner-swiper .swiper", {
// 		loop: true,
// 		speed: 5000,
// 		autoplay: {
// 			delay: 0,
// 			disableOnInteraction: false,
// 		},
// 		breakpoints: {
// 			320: {
// 				slidesPerView: 2,
// 			},
// 			576: {
// 				slidesPerView: 3,
// 			},
// 			768: {
// 				slidesPerView: 4,
// 			},
// 			1024: {
// 				slidesPerView: 6,
// 			},
// 			1200: {
// 				slidesPerView: 8,
// 			},
// 		},
// 	});

// 	var partnerSwiper = new Swiper(".swiper-zone-nav .swiper", {
// 		loop: false,
// 		speed: 1200,
// 		autoplay: false,
// 		lazy: {
// 			loadPrevNext: true,
// 		},
// 		spaceBetween: 0,
// 		observer: true,
// 		observeParents: true,
// 		observeSlideChildren: true,
// 		slidesPerView: "auto",
// 		slideToClickedSlide: true,
// 		navigation: {
// 			nextEl: ".swiper-zone-nav .next",
// 			prevEl: ".swiper-zone-nav .prev",
// 		},
// 	});

// 	$(".init-swiper .swiper").each(function (index) {
// 		var $this = $(this);
// 		$this.addClass("swiper-init-" + index);
// 		$this
// 			.parent()
// 			.find(".prev")
// 			.addClass("prev-nav-" + index);
// 		$this
// 			.parent()
// 			.find(".next")
// 			.addClass("next-nav-" + index);
// 		$this
// 			.parent()
// 			.find(".swiper-pagination")
// 			.addClass("pagination-inst-" + index);

// 		var swiper = new Swiper(".swiper-init-" + index, {
// 			loop: false,
// 			rewind: true,
// 			speed: 1200,
// 			autoplay: {
// 				delay: 3000,
// 				disableOnInteraction: false,
// 			},
// 			lazy: {
// 				loadPrevNext: true,
// 			},
// 			spaceBetween: 0,
// 			observer: true,
// 			observeParents: true,
// 			observeSlideChildren: true,
// 			slidesPerView: "auto",
// 			navigation: {
// 				nextEl: ".next-nav-" + index,
// 				prevEl: ".prev-nav-" + index,
// 			},
// 			pagination: {
// 				el: ".pagination-inst-" + index,
// 				type: "bullets",
// 				clickable: true,
// 				dynamicBullets: true,
// 				dynamicMainBullets: 1,
// 			},
// 		});
// 	});

// 	var galleryThumb = new Swiper(".gallery-thumb  .swiper", {
// 		spaceBetween: 5,
// 		breakpointsInverse: true,
// 		slidesPerView: "auto",
// 		loop: false,
// 		watchSlidesVisibility: true,
// 		watchSlidesProgress: true,
// 	});
// 	var historyMain = new Swiper(".gallery-main .swiper", {
// 		slidesPerView: 1,
// 		centeredSlides: true,
// 		lazy: {
// 			loadPrevNext: true,
// 		},

// 		speed: 750,
// 		loop: false,
// 		thumbs: {
// 			swiper: galleryThumb,
// 		},
// 		navigation: {
// 			nextEl: ".gallery-thumb .next",
// 			prevEl: ".gallery-thumb .prev",
// 		},
// 	});




// 	$(".vertical-swiper .swiper").each(function (index) {
// 		var $this = $(this);
// 		$this.addClass("instance-vertical-" + index);
// 		$this
// 			.parent()
// 			.find(".prev")
// 			.addClass("prev-vertical-" + index);
// 		$this
// 			.parent()
// 			.find(".next")
// 			.addClass("next-vertical-" + index);

// 		var vertical = new Swiper(".instance-vertical-" + index, {
// 			speed: 1205,
// 			loop: false,
// 			autoplay: false,
// 			slideToClickedSlide: true,
// 			centeredSlides:true,

// 			lazy: {
// 				loadPrevNext: true,
// 			},
// 			observer: true,
// 			observeParents: true,
// 			observeSlideChildren: true,
// 			spaceBetween: 30,
// 			centeredSlides: true,
// 			breakpoints: {
// 				200: {
// 					slidesPerView: 2,
// 					spaceBetween: 10,
// 				},
// 				420: {
// 					slidesPerView: 3,
// 				},
// 				769: {
// 					slidesPerView: 4,
// 				},
// 				1024.1: {
// 					slidesPerView: 5,
// 					spaceBetween: 20,
// 					direction: "vertical",
// 				},
// 			},
// 		});
// 	});
// }

// function mappingFunc() {
// 	new MappingListener({
// 		selector: ".search-wrap",
// 		mobileWrapper: ".mobile-top-nav",
// 		mobileMethod: "appendTo",
// 		desktopWrapper: ".language-wrap",
// 		desktopMethod: "insertBefore",
// 		breakpoint: 1200.1,
// 	}).watch();
// }

const bodyWrap = document.querySelector("body");
const menuToggle = document.querySelector(".site-menu-toggle");
const closeMenu = document.querySelector(".close-menu");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const searchToggle = document.querySelector(".search-toggle");
const searchWrap = document.querySelector(".search-overlay");
const counterItem = document.querySelectorAll(".counter");
const setBackground = document.querySelectorAll("[setBackground]");
const sideNav = document.querySelector(".sidenav-group");

document.addEventListener("DOMContentLoaded", () => {
	hideElement();
	mappingFunc();
	generalFunction.init();
	customLevel3Dropdown();
});
window.addEventListener("load", function () {
	swiperInit();
});
window.addEventListener("resize", function () {
	let width = window.innerWidth;
	if (width <= 1200.1) {
		hideElement();
		mappingFunc();
	}
});
window.addEventListener("scroll", function () {
	const width = window.innerWidth;

	const scrollTop = window.scrollY || document.documentElement.scrollTop;

	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

const generalFunction = {
	toggleFunc: () => {
		menuToggle.addEventListener("click", () => {
			menuMobile.classList.toggle("is-open");
			hamburger.classList.toggle("is-active");
			bodyWrap.classList.toggle("overlay-bg");
		});
		searchToggle.addEventListener("click", () => {
			searchWrap.classList.toggle("is-open");
			bodyWrap.classList.toggle("overlay-bg");
		});
		closeMenu.addEventListener("click", () => {
			menuMobile.classList.remove("is-open");
			hamburger.classList.remove("is-active");
			bodyWrap.classList.remove("overlay-bg");
		});
	},

	dropdownMenu: () => {
		if (window.innerWidth < 1200.1) {
			document
				.querySelectorAll(".drop-down .title em")
				.forEach(function (em) {
					em.addEventListener("click", function () {
						var dropDown = this.parentElement.parentElement;
						var navSub = dropDown.querySelector(".nav-sub");

						if (dropDown.classList.contains("is-open")) {
							document
								.querySelectorAll(".drop-down .nav-sub")
								.forEach(function (sub) {
									sub.style.display = "none";
								});
							document
								.querySelectorAll(".drop-down")
								.forEach(function (item) {
									item.classList.remove("is-open");
								});
						} else {
							document
								.querySelectorAll(".drop-down .nav-sub")
								.forEach(function (sub) {
									sub.style.display = "none";
								});
							document
								.querySelectorAll(".drop-down")
								.forEach(function (item) {
									item.classList.remove("is-open");
								});
							navSub.style.display = "block";
							dropDown.classList.add("is-open");
						}
					});
				});
		}
	},
	counterAnimate: () => {
		if (counterItem !== null) {
			if (counterItem.length) {
				const counterUp = window.counterUp.default;
				const callback = (entries) => {
					entries.forEach((entry) => {
						const el = entry.target;
						if (
							entry.isIntersecting &&
							!el.classList.contains("is-visible")
						) {
							counterUp(el, {
								duration: 2000,
								delay: 200,
							});
							el.classList.add("is-visible");
						}
					});
				};
				const IO = new IntersectionObserver(callback, { threshold: 1 });
				counterItem.forEach((counter) => {
					IO.observe(counter);
				});
			}
		}
	},

	hideElement: () => {
		let width = window.innerWidth;
		if (width <= 1200.1) {
			const [search, menu] = [".search-overlay", ".nav-primary-menu"].map(
				(selector) => document.querySelector(selector)
			);
			[search, menu].forEach((el) => (el.style.display = "flex"));
		}
	},
	init: () => {
		generalFunction.toggleFunc();
		generalFunction.dropdownMenu();
		generalFunction.counterAnimate();
		generalFunction.hideElement();
	},
};

function hideElement() {
	let width = window.innerWidth;
	if (width <= 1200.1) {
		const [search, menu] = [".search-overlay", ".nav-primary-menu"].map(
			(selector) => document.querySelector(selector)
		);
		[search, menu].forEach((el) => (el.style.display = "flex"));
	}
}
function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
	var partnerSwiper = new Swiper(".home-partner-swiper .swiper", {
		loop: true,
		speed: 5000,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 6,
			},
			1200: {
				slidesPerView: 8,
			},
		},
	});
	var primarySwiper = new Swiper(".six-swiper .swiper", {
		// Optional parameters
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
			1200: {
				slidesPerView: 6,
			},
		},
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
	});
}

function isTouchDevice() {
	return (
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0
	);
}

function customLevel3Dropdown() {
	const isMobile = isTouchDevice();
	let eventType = "mouseenter";
	if (isMobile) eventType = "click";

	document
		.querySelectorAll(
			".mobile-nav-wrap .nav-primary-menu .drop-down-lv-2 .title-lv-2"
		)
		.forEach(function (element) {
			element.addEventListener(eventType, function () {
				this.closest("li").querySelector(">ul").style.display = "block";
				this.closest("li").classList.add("toggle-dropdown");
			});
		});

	document
		.querySelectorAll(".mobile-nav-wrap .nav-primary-menu .drop-down-lv-2")
		.forEach(function (element) {
			element.addEventListener("mouseleave", function () {
				this.closest("li").querySelector(">ul").style.display = "none";
				this.closest("li").classList.remove("toggle-dropdown");
			});
		});
}

function mappingFunc() {
	new MappingListener({
		selector: ".search-wrap",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
